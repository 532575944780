.slice.reason-neaf {
  padding-top: 52px;
  padding-bottom: 52px;
}
@media only screen and (max-width: 520px) {
  .slice.reason-neaf {
    padding: 40px 0 0;
    lost-center: 420px;
  }
}
.slice.reason-neaf .subtitle {
  margin-bottom: 40px;
}
.slice.reason-neaf .reasons .reason {
  lost-column: 1/3 3 0;
  text-align: center;
  padding: 0 25px;
}
@media only screen and (max-width: 1024px) {
  .slice.reason-neaf .reasons .reason {
    padding: 0 10px;
  }
}
@media only screen and (max-width: 520px) {
  .slice.reason-neaf .reasons .reason {
    lost-column: 1/1 1 0;
    margin-bottom: 20px;
  }
}
.slice.reason-neaf .reasons .reason .reason-icon {
  display: block;
  margin-bottom: 14px;
}
.slice.reason-neaf .reasons .reason .reason-title,
.slice.reason-neaf .reasons .reason .reason-content {
  color: #666;
}
.slice.reason-neaf .reasons .reason .reason-title {
  font-size: 14px;
}
