@import "../global/___variables"

.slice.reason-neaf
    padding-top 52px
    padding-bottom 52px
    +below(520px)
        padding 40px 0 0
        lost-center 420px
    .subtitle
        margin-bottom 40px
    .reasons
        .reason
            lost-column 1/3 3 0
            text-align center
            padding 0 25px
            +below(1024px)
                padding 0 10px
            +below(520px)
                lost-column 1/1 1 0
                margin-bottom 20px
            .reason-icon
                display block
                margin-bottom 14px
            .reason-title, .reason-content
                color texts2
            .reason-title
                font-size 14px
